@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './flatpickr.css';
@import './chatwoot.css';


.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@layer base {
  html {
    @apply scroll-smooth box-border;
  }

  body {
    @apply bg-white scroll-smooth box-border;
    -webkit-tap-highlight-color: transparent;
  }

  #__next {
    @apply scroll-smooth box-border min-h-screen relative;
  }

  a {
    @apply no-underline;
  }
  
  a:hover {
    @apply cursor-pointer;
  }
  
  h1 {
    @apply font-extrabold text-3xl text-primary-950 md:text-4xl xl:text-5xl 2xl:text-6xl text-center lg:text-left;
  }
  
  h2 {
    @apply font-semibold text-primary-950 text-2xl md:text-3xl xl:text-4xl text-center lg:text-left;
  }
  
  h3 {
    @apply font-medium text-primary-950 md:text-lg xl:text-xl text-center lg:text-left;
  }
  
  h4 {
    @apply font-medium text-primary-950 md:text-lg xl:text-xl text-center lg:text-left;
  }
  
  p {
    @apply font-light text-primary-950 text-center lg:text-left;
  }

  span {
    @apply text-primary-950;
  }
  
  .markdown {
    @apply [&_*]:mb-4 [&_a]:underline [&_a]:text-primary-500 [&_ul]:list-disc [&_ul]:ml-5 [&_ul]:list-inside [&_li]:mb-3 [&_a_li_span_p]:font-light [&_strong]:font-semibold [&_ol]:list-decimal [&_ol]:ml-5 [&_ol]:list-inside;
  }

  .markdown h1 {
    @apply font-semibold text-2xl text-primary-950 md:text-3xl xl:text-4xl;
  }

  .markdown h2 {
    @apply font-semibold text-xl text-primary-950 md:text-2xl xl:text-3xl text-left;
  }

  .markdown h3 {
    @apply font-medium text-base text-primary-950 md:text-xl xl:text-2xl text-left;
  }

  .markdown p, span {
    @apply text-left leading-7;
  }
  
  strong {
    @apply font-semibold
  }
  
  .scroll-margin-top {
    @apply scroll-mt-16 xl:scroll-mt-[72px];
  }
  
  .margin-header {
    @apply mt-16 xl:mt-[72px] scroll-mt-16 xl:scroll-mt-[72px];
  }
  
  .scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  
  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 650px;
    border-radius: 0;
  }
  
  .drawer-animated {
    transform: translateX(-100%);
  }
  
  .drawer-animated-slide-in {
    animation: slide-in 0.3s forwards;
  }

  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0%);
    }
  }

  .slidedown-button.primary {
    @apply !bg-primary-600 !text-white;
  }

  .slidedown-button.secondary {
    @apply !text-primary-500;
  }

  * {
    @apply focus-visible:outline-none;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }

  input[type="date"], 
  input[type="time"], 
  input[type="datetime-local"], 
  input[type="month"] {
      -webkit-appearance: none !important;
  }

  @keyframes ping {
    0% {
      scale: 1;
    }
    40% {
      scale: 1.1;
    }
    100% {
      scale: 1;
    }
  }

  .button-ping {
    animation: ping .8s infinite;
  }

  .button-shinny {
    @apply relative after:rounded-full after:mx-auto overflow-hidden after:absolute after:inset-0 after:top-0 after:left-0 after:w-0 after:h-full after:bg-primary-100/20 after:transition-all after:duration-200 after:ease-linear hover:after:w-full hover:after:rounded-full
  }

  .circle-button-shinny {
    @apply relative !shadow-none after:m-auto overflow-hidden after:rounded-full after:absolute after:inset-0 after:top-0 after:left-0 after:size-0 after:bg-primary-100/20 after:transition-all after:duration-200 after:ease-linear hover:after:size-full
  }
}

@layer components {
  .section {
    @apply relative w-full py-16 lg:py-24 scroll-mt-24 flex flex-col items-center justify-center;
  }

  .section-container {
    @apply xl:max-w-[1440px] mx-auto px-8;
  }

  .hero-container {
    @apply xl:max-w-[1600px] mx-auto px-8;
  }
}

.swiper-pagination-bullet-active {
  background: white !important;
}

